import { Card, Button, Tooltip } from 'antd';
import { UserOutlined, DisconnectOutlined, ApiOutlined } from '@ant-design/icons';
import { ReactNode } from 'react';
import Image from 'next/image';

const { Meta } = Card;

type HitchCardType = {
  iconAlt: string;
  iconSrc: string;
  title: string;
  description: ReactNode;
  onHitch?: () => void;
  authUrl?: string;
  onDeleteHitch: () => void;
  isConnected: boolean;
  actionButtons?: ReactNode[];
  toolTipText?: string;
};

const HitchCard = ({
  iconAlt,
  iconSrc,
  title,
  description,
  onHitch,
  authUrl,
  onDeleteHitch,
  isConnected,
  actionButtons = [],
  toolTipText,
}: HitchCardType): JSX.Element => {
  const actions = [];

  if (onHitch) {
    actions.push(
      <Tooltip placement="topLeft" title={`Connect ${title}`}>
        <Button type="link" icon={<ApiOutlined key="hitch" />} onClick={onHitch}>
          {`Start`}
        </Button>
      </Tooltip>
    );
  } else if (!isConnected) {
    const button = (
      <Button disabled={!authUrl} type="link" icon={<ApiOutlined key="hitch" />} href={authUrl}>
        {`Connect ${title}`}
      </Button>
    );
    const actionButton =
      authUrl || !toolTipText ? (
        button
      ) : (
        <Tooltip placement="topLeft" title={toolTipText}>
          {button}
        </Tooltip>
      );
    actions.push(actionButton);
  } else {
    actions.push(
      <Button
        danger
        type="link"
        icon={<DisconnectOutlined key="disconnect" />}
        onClick={onDeleteHitch}
      >
        {`Disconnect ${title}`}
      </Button>
    );
  }

  actionButtons.forEach((action) => actions.push(action));

  return (
    <Card
      style={{ width: 400 }}
      cover={
        <div style={{ padding: '25%' }}>
          <Image alt={iconAlt} src={iconSrc} layout="responsive" width="400" height="400" />
        </div>
      }
      actions={actions}
    >
      <Meta
        avatar={<UserOutlined key={title} />}
        title={title}
        description={<div style={{ minHeight: '80px' }}>{description}</div>}
      />
    </Card>
  );
};

export default HitchCard;
