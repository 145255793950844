import firebase from 'firebase/app';
import getCallback, { createCallback } from 'services/utils/getCallback';
import handleFetchResponse from 'services/utils/handleFetchResponse';

const HITCH_ID = 'teamleader';

// TODO duplicate types with back-end
export type TeamleaderUserData = {
  user: {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
  };
  account: {
    id: string;
  };
};

// TODO deprecated: is replaced with useHitch
export async function getTeamleaderUser(user: firebase.User): Promise<TeamleaderUserData | null> {
  try {
    const userInfo = await firebase
      .firestore()
      .collection('hitches')
      .doc(HITCH_ID)
      .collection('users')
      .doc(user.uid)
      .get();

    if (!userInfo.exists) {
      return null;
    }

    return userInfo.data() as TeamleaderUserData;
  } catch (e) {
    // TODO handle errors in the process..
    return null;
  }
}

export async function getTeamleaderAuthUrl(
  pathname: string,
  hitchId: string,
  hitchRef?: string,
  user?: firebase.User
): Promise<string> {
  const idToken = user ? await user.getIdToken() : undefined;

  const hitchRefArg = hitchRef ? `&hitchRef=${hitchRef}` : '';

  const response = await fetch(
    `${process.env.NEXT_PUBLIC_AUTH_URI}/hitch/teamleader?callback=${getCallback(
      pathname
    )}&hitchId=${hitchId}${hitchRefArg}`,
    {
      method: 'GET',
      ...(idToken
        ? {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + idToken,
            },
          }
        : {}),
    }
  );
  return (await handleFetchResponse(response)).url;
}

export async function deleteTeamleader(
  user: firebase.User,
  hitchRef: string,
  pathname: string
): Promise<void> {
  const idToken = await user.getIdToken();

  const response = await fetch(
    `${
      process.env.NEXT_PUBLIC_AUTH_URI
    }/hitch/teamleader?hitchRef=${hitchRef}&callback=${createCallback(
      `${process.env.NEXT_PUBLIC_HITCH_URL}${pathname}`
    )}`,
    {
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + idToken,
      },
    }
  );
  await handleFetchResponse(response);
}
