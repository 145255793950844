export default function appendToPathname(
  pathname: string,
  searchParams: Record<string, string>
): string {
  const url = new URL(`http://fakeUrl${pathname}`);
  const params = new URLSearchParams(url.search.slice(1));

  Object.entries(searchParams).forEach(([key, value]) => {
    if (params.has(key)) {
      // dont add duplicates..connect is sometimes already present
      return params.set(key, value);
    }
    return params.append(key, value);
  });
  const newPathname = `${url.pathname}?${params.toString()}`;
  return newPathname;
}
