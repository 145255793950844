import React, { useEffect, useState } from 'react';
import { Col, Button, Input, Row, Divider, Tooltip, Spin } from 'antd';
import HitchCard from 'components/public/integrations/hitchCard';
import useShopify from 'components/public/integrations/useShopify';
import useTeamleader from 'components/public/integrations/useTeamleader';
import { useIntl } from 'react-intl';
import { ConnectConfiguration } from 'components/public/integrations/useHitchConfiguration';

const Connect = ({
  noIntro,
  hitchId,
  connectConfiguration,
}: {
  noIntro?: boolean;
  hitchId: string;
  connectConfiguration: ConnectConfiguration;
}): JSX.Element => {
  const { formatMessage } = useIntl();
  const [shopNameField, setShopNameField] = useState<string | undefined>();
  const currentShopName =
    connectConfiguration.hitch?.data?.users &&
    connectConfiguration.hitch?.data?.users['shopify']?.appUrl
      ? connectConfiguration.hitch?.data?.users['shopify'].appUrl
          .replace('https://', '')
          .replace('.myshopify.com', '')
      : undefined;
  const teamleaderUser =
    connectConfiguration.hitch?.data?.users && connectConfiguration.hitch.data.users['teamleader'];
  const shopifyUser =
    connectConfiguration.hitch?.data?.users && connectConfiguration.hitch.data.users['shopify'];
  const { authUrl: teamleaderAuthUrl, deleteUser: deleteTeamleaderUser } = useTeamleader(
    hitchId,
    connectConfiguration.hitch?.data?.hitchRef
  );

  const {
    authUrl: shopifyAuthUrl,
    clearAuthUrl,
    deleteUser: deleteShopifyUser,
    updateShopName,
    shopName,
  } = useShopify(hitchId, connectConfiguration.hitch?.data?.hitchRef);

  useEffect(() => {
    if (currentShopName) {
      updateShopName(currentShopName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentShopName]);

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  let onHitchShopify, onHitchTeamleader;

  return (
    <Spin spinning={!!connectConfiguration.authLoading || !!connectConfiguration.hitch.loading}>
      <Row justify="center" gutter={[32, 32]}>
        {!noIntro && (
          <Col span={24}>
            <h2>
              {formatMessage({
                defaultMessage: 'Connect your apps',
                description: 'onboarding/connect/title',
              })}
            </h2>
            <p>
              {formatMessage({
                defaultMessage: 'To help you with this, we should be granted access to your apps.',
                description: 'onboarding/connect/description/1',
              })}
            </p>
            <p>
              {formatMessage({
                defaultMessage: `No worries, your data is only used to help you with this task in`,
                description: 'onboarding/connect/description/2',
              })}{' '}
              <a href="/terms-of-service" target="_blank">
                {formatMessage({
                  defaultMessage: `the most optimal and
          transparant way possible.`,
                  description: 'onboarding/connect/description/3/link',
                })}
              </a>
            </p>
          </Col>
        )}
        <Col>
          <HitchCard
            iconAlt="shopify"
            iconSrc="/shopify/icon.png"
            title="Shopify"
            authUrl={shopifyAuthUrl}
            onHitch={onHitchShopify}
            onDeleteHitch={deleteShopifyUser}
            isConnected={connectConfiguration?.hitch?.isConnected('shopify')}
            toolTipText={`First fill in your shop name`}
            description={
              connectConfiguration?.hitch?.isConnected('shopify') ? (
                <div>
                  <p>
                    <span role="img" aria-label="wave">
                      👋
                    </span>{' '}
                    {shopifyUser?.displayName}, you are successfully connected to {shopName}
                  </p>
                </div>
              ) : (
                <div>
                  <p>
                    <span role="img" aria-label="strong">
                      💪
                    </span>
                    Hitch your Shopify store {shopNameField && `named "${shopNameField}", `} to hike
                    with Teamleader{' '}
                  </p>
                  <Divider />
                  <p>Shopify wants to know which shop you want to connect to</p>

                  <div style={{ marginBottom: 16 }}>
                    <Tooltip
                      title={
                        <p>
                          Even if you use a custom domain like <a>https://my-shop-name.com</a>, you
                          should enter the shopnameField from your original shopify account
                        </p>
                      }
                    >
                      <Input
                        addonBefore="https://"
                        suffix=".myshopify.com"
                        defaultValue=""
                        placeholder="shopname"
                        disabled={!!shopName}
                        value={shopName || shopNameField}
                        onChange={(e) => {
                          setShopNameField(e.target.value);
                          clearAuthUrl();
                        }}
                      />
                    </Tooltip>
                  </div>
                  {!shopName ? (
                    <Button
                      type="primary"
                      disabled={shopName === shopNameField}
                      onClick={() => {
                        updateShopName(shopNameField);
                      }}
                    >
                      Confirm shopname
                    </Button>
                  ) : (
                    <Button
                      type="primary"
                      onClick={() => {
                        updateShopName(undefined);
                      }}
                    >
                      Change shopname
                    </Button>
                  )}
                </div>
              )
            }
          />
        </Col>
        <Col>
          {connectConfiguration?.hitch?.isConnected('shopify') && (
            <HitchCard
              iconAlt="teamleader"
              iconSrc="/teamleader/icon.png"
              title="Teamleader"
              authUrl={teamleaderAuthUrl}
              onDeleteHitch={deleteTeamleaderUser}
              onHitch={onHitchTeamleader}
              isConnected={connectConfiguration?.hitch?.isConnected('teamleader')}
              description={
                connectConfiguration?.hitch?.isConnected('teamleader') ? (
                  <div>
                    <p>
                      <span role="img" aria-label="wave">
                        👋
                      </span>{' '}
                      {teamleaderUser?.displayName}, you are successfully connected
                    </p>
                  </div>
                ) : (
                  <div>
                    <p>
                      <span role="img" aria-label="strong">
                        💪
                      </span>{' '}
                      Hitch your Teamleader account to hike with Shopify{' '}
                    </p>
                  </div>
                )
              }
            />
          )}
        </Col>
      </Row>
    </Spin>
  );
};

export default Connect;
