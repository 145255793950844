// import { useEffect, useState } from 'react';
import firebase from 'firebase/app';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { Data } from 'react-firebase-hooks/firestore/dist/firestore/types';
import { Hitch } from 'services/hitchit/types/hitch/Hitch';

export type HitchHookResponse = {
  loading?: boolean;
  data?: Hitch;
  isConnected: (appName?: string) => boolean;
};
export default function useHitch(
  hitchId?: string,
  uid?: string,
  hitchRef?: string
): HitchHookResponse {
  const [hitchResponse, loading, error] = useCollectionData<Hitch>(
    uid && hitchId
      ? firebase
          .firestore()
          .collection('users')
          .doc(uid)
          .collection('hitch')
          .where('hitchId', '==', hitchId)
      : undefined,
    {
      idField: 'id',
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let isConnected = (_appName?: string): boolean => {
    if (loading) {
      return false;
    }
    if (!hitchResponse || hitchResponse.length === 0) {
      return false;
    }
    return false;
  };

  if (error) {
    throw new Error(error.message);
  }

  if (loading) {
    return { loading, isConnected };
  }

  if (!hitchResponse || hitchResponse.length === 0) {
    return { loading: false, isConnected };
  }

  const getHitchData = (): Data<Hitch, '', ''> | undefined => {
    const connectedHitches = hitchResponse.filter((hitch) => hitch.isConnected === true);

    // TODO be sure that the connected one has the same account as hitchRef one
    const hitchDataFromHitchWithRef =
      hitchRef && hitchResponse.find((hitch) => hitch.hitchRef === hitchRef);

    // HitchRef was an unknown one ; something unusual happened, return no hitch
    if (!hitchDataFromHitchWithRef && hitchRef) {
      return;
    }
    // take the one with the most connections by default, otherwise the one with the hitchRef
    if (hitchDataFromHitchWithRef && hitchDataFromHitchWithRef.isConnected) {
      return hitchDataFromHitchWithRef;
    }

    // in case there are connectedHitches
    if (connectedHitches.length > 0) {
      // without hitchRef ; take the first connected one
      if (!hitchDataFromHitchWithRef) {
        return connectedHitches[0];
        // with hitchRef ; check for connectedHitch which matches the one with hitchRef (to enrich it with another connection)
      }

      const connectedHitchWithMatchingCredentials = connectedHitches.find((connectedHitch) => {
        const matchingCredentials = Object.entries(hitchDataFromHitchWithRef.credentials).find(
          ([appName, appRef]) => {
            if (connectedHitch.credentials[appName] === appRef) {
              return true;
            } else {
              return false;
            }
          }
        );
        return !!matchingCredentials;
      });
      if (connectedHitchWithMatchingCredentials) {
        return connectedHitchWithMatchingCredentials;
      }
    }

    return hitchDataFromHitchWithRef || hitchResponse[0];
  };

  /**
   * Checks for connected + not broken
   * When no appName is given, checks complete connection, otherwise the connection of a certain app
   */
  const isConnectedFn = (hitchData: Data<Hitch, '', ''>) => {
    return (appName?: string): boolean => {
      if (loading) {
        return false;
      }
      if (!appName) {
        return (
          hitchData?.isConnected &&
          (!hitchData.broken || !Object.values(hitchData.broken).find((value) => !!value))
        );
      }
      return !!(
        hitchData?.users &&
        hitchData.users[appName] &&
        (!hitchData?.broken || !hitchData.broken[appName])
      );
    };
  };

  const hitchData = getHitchData();
  if (hitchData) {
    isConnected = isConnectedFn(hitchData);
  }

  return { data: hitchData, isConnected };
}

// function getUserForApp<USER extends HitchUser>(app: string): USER | undefined {
//   if (hitch && hitch.users[app]) {
//     return hitch1User as USER;
//   }
//   if (hitch2User?.hitch === app) {
//     return hitch2User as USER;
//   }
//   return;
// }
// getUserForApp: <USER extends HitchUser>(app: string) => USER | undefined;
