import firebase from 'firebase/app';
import getCallback, { createCallback } from 'services/utils/getCallback';
import handleFetchResponse from 'services/utils/handleFetchResponse';

export type ShopifyUserData = {
  user: {
    id: string;
    email: string;
    fullName: string;
  };
};

export async function getShopifyAuthUrl(
  pathname: string,
  shop: string,
  hitchId: string,
  hitchRef?: string,
  user?: firebase.User
): Promise<string> {
  const idToken = user ? await user.getIdToken() : undefined;

  const hitchRefArg = hitchRef ? `&hitchRef=${hitchRef}` : '';

  const response = await fetch(
    `${process.env.NEXT_PUBLIC_AUTH_URI}/hitch/shopify?callback=${getCallback(
      pathname
    )}&shop=${shop}&hitchId=${hitchId}${hitchRefArg}`,
    {
      method: 'GET',
      ...(idToken
        ? {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + idToken,
            },
          }
        : {}),
    }
  );
  return (await handleFetchResponse(response)).url;
}

export async function deleteShopify(
  user: firebase.User,
  hitchRef: string,
  pathname: string
): Promise<void> {
  const idToken = await user.getIdToken();

  const response = await fetch(
    `${
      process.env.NEXT_PUBLIC_AUTH_URI
    }/hitch/shopify?hitchRef=${hitchRef}&callback=${createCallback(
      `${process.env.NEXT_PUBLIC_HITCH_URL}${pathname}`
    )}`,
    {
      method: 'DELETE',
      headers: {
        Authorization: 'Bearer ' + idToken,
      },
    }
  );
  await handleFetchResponse(response);
}
