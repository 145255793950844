import React, { useState } from 'react';
import { useRouter } from 'next/router';
import { useEffect, useContext } from 'react';
import Intro from '../../public/Intro/Intro';
import { message } from 'antd';
import { UserAuthenticationContext } from 'components/context/UserAuthenticationProvider';

import useConnectConfiguration from 'components/public/integrations/useConnectConfiguration';
import Connect from 'components/HitchById/OnboardingFlow/Connect';

export default function LoginContainer({ redirectPath }: { redirectPath?: string }): JSX.Element {
  const { user, authLoading } = useContext(UserAuthenticationContext);
  const [showMessage, setShowMessage] = useState(false);

  const connectConfiguration = useConnectConfiguration('shopify$$teamleader');

  const { replace } = useRouter();
  useEffect(() => {
    if (
      user &&
      user.uid &&
      !user.isAnonymous &&
      connectConfiguration?.hitch?.isConnected('teamleader') &&
      connectConfiguration?.hitch?.isConnected('shopify') &&
      !showMessage
    ) {
      setShowMessage(true);
      message.success(
        `${
          user.displayName
            ? `${user.displayName}, you are successfully logged in`
            : 'You are successfully logged in'
        }`
      );
      if (redirectPath) {
        replace(redirectPath);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, connectConfiguration?.hitch]);

  if (authLoading) {
    return <div>loading</div>;
  }

  return (
    <div>
      <Intro
        title="Login"
        image={{ src: '/logo.png', alt: 'logo', width: '1779', height: '1608' }}
        descriptionText="Login via Teamleader and Shopify"
      />
      <Connect
        noIntro
        hitchId={'shopify$$teamleader'}
        connectConfiguration={connectConfiguration}
      />
    </div>
  );
}
