import firebase from 'firebase/app';
import { useRouter } from 'next/router';
import useHitch, { HitchHookResponse } from 'services/firebase/hooks/useHitch';
import useProfile from 'components/private/hooks/useProfile';

export type ConnectConfiguration = {
  hitch: HitchHookResponse;
  firebaseUser?: firebase.User;
  authLoading?: boolean;
};

export type BackendError = 'authenticate' | undefined;

export default function useConnectConfiguration(hitchId: string): ConnectConfiguration {
  const { user, authLoading } = useProfile();

  const { query } = useRouter();
  const hitch = useHitch(hitchId, user?.uid, query.hitchRef as string | undefined);

  return {
    hitch,
    firebaseUser: user,
    authLoading,
  };
}
