import { useEffect, useContext, useState, useCallback } from 'react';
import { getTeamleaderAuthUrl, deleteTeamleader } from '../../../services/teamleader';
import { useRouter } from 'next/router';
import { HitchUser } from 'services/hitchit/types/hitch/Hitch';
import { UserAuthenticationContext } from 'components/context/UserAuthenticationProvider';
import firebase from 'firebase';
import { message } from 'antd';
import { handleHikeError } from 'services/hitchit';

type TeamleaderHook = {
  user?: HitchUser;
  authUrl?: string;
  deleteUser: () => void;
  error: BackendError;
};

export type BackendError = 'authenticate' | undefined;

export default function useTeamleader(hitchId: string, hitchRef?: string): TeamleaderHook {
  const { asPath } = useRouter();
  const { user: firebaseUser } = useContext(UserAuthenticationContext);

  const [authUrl, setAuthUrl] = useState<string>();
  const [error, setError] = useState<BackendError>();

  const updateAuthUrl = useCallback(async () => {
    try {
      const url = await getTeamleaderAuthUrl(asPath, hitchId, hitchRef, firebaseUser);
      setAuthUrl(url);
    } catch (e) {
      setError('authenticate');
    }
  }, [asPath, hitchId, hitchRef, firebaseUser]);

  useEffect(() => {
    firebase.auth().onAuthStateChanged(async function () {
      updateAuthUrl();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    updateAuthUrl();
  }, [updateAuthUrl]);

  async function deleteUser(): Promise<void> {
    if (!firebaseUser || !hitchRef) {
      return;
    }
    try {
      await deleteTeamleader(firebaseUser, hitchRef, asPath);
      updateAuthUrl();
    } catch (e) {
      const { error } = handleHikeError(e);

      if (error?.message) {
        message.error(error.message);
      }
    }
  }

  return { authUrl, deleteUser, error };
}
