import firebase from 'firebase/app';
import { useEffect, useContext, useState, useCallback } from 'react';
import { getShopifyAuthUrl, deleteShopify } from '../../../services/shopify';
import { useRouter } from 'next/router';
import { HitchUser } from 'services/hitchit/types/hitch/Hitch';
import { UserAuthenticationContext } from 'components/context/UserAuthenticationProvider';
import { handleHikeError } from 'services/hitchit';
import { message } from 'antd';

type ShopifyHook = {
  user?: HitchUser;
  authUrl?: string;
  clearAuthUrl: () => void;
  deleteUser: () => void;
  updateShopName: (shopName?: string) => void;
  shopName?: string;
  error: BackendError;
};

export type BackendError = 'authenticate' | undefined;

export default function useShopify(
  hitchId: string,
  hitchRef?: string
  //   { shopName }: ShopifyHookOptions
): ShopifyHook {
  const { asPath } = useRouter();
  const { user: firebaseUser } = useContext(UserAuthenticationContext);

  const [authUrl, setShopifyAuthUrl] = useState<string | undefined>();
  const [shopName, setShopName] = useState<string>();
  const [error, setError] = useState<BackendError>();

  const updateAuthUrl = useCallback(async () => {
    if (shopName) {
      try {
        const url = await getShopifyAuthUrl(asPath, shopName, hitchId, hitchRef, firebaseUser);
        setShopifyAuthUrl(url);
      } catch (e) {
        setError('authenticate');
      }
    }
  }, [asPath, shopName, hitchId, hitchRef, firebaseUser]);

  useEffect(() => {
    firebase.auth().onAuthStateChanged(async function () {
      updateAuthUrl();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    updateAuthUrl();
  }, [shopName, updateAuthUrl]);

  async function deleteUser(): Promise<void> {
    if (!firebaseUser || !hitchRef) {
      return;
    }
    try {
      await deleteShopify(firebaseUser, hitchRef, asPath);
      updateAuthUrl();
    } catch (e) {
      const { error } = handleHikeError(e);

      if (error?.message) {
        message.error(error.message);
      }
    }
  }

  function updateShopName(updatedShopName?: string): void {
    setShopName(updatedShopName);
  }

  function clearAuthUrl(): void {
    setShopifyAuthUrl(undefined);
    setShopName(undefined);
  }

  return { authUrl, deleteUser, shopName, updateShopName, error, clearAuthUrl };
}
