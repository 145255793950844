import appendToPathname from './appendToPathname';

export default function getCallback(
  pathname: string,
  searchParams: Record<string, string> = {}
): string {
  const callback = encodeURIComponent(
    `${process.env.NEXT_PUBLIC_HITCH_URL}${appendToPathname(pathname, {
      ...searchParams,
      step: 'connect',
    })}`
  );

  return callback;
}

export function createCallback(url: string): string {
  return encodeURIComponent(url);
}
